// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ShortInformation_shortInformationWrapper__T3\\+Mn {
  display: flex;
  align-content: center;
  justify-content: space-around;
  gap: 48px;
}
@media screen and (max-width: 800px) {
  .ShortInformation_shortInformationWrapper__T3\\+Mn {
    flex-direction: column;
  }
}

.ShortInformation_infoImage__zxxif {
  width: 40vw;
  height: 40vw;
  border-radius: 100%;
  margin: 0 auto;
}

.ShortInformation_infoWrapper__q8wDa {
  max-width: 595px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 24px;
}

.ShortInformation_infoTitle__8-KJC {
  font-family: "Inter-Bold";
  font-size: 20px;
  letter-spacing: 0.4px;
  text-align: center;
}

.ShortInformation_infoDescription__q0Ppu {
  font-family: "Inter-Medium";
  font-size: 16px;
  letter-spacing: 1.8px;
  line-height: 30px;
  text-align: center;
}

.ShortInformation_buttonWrapper__DoUI4 {
  display: flex;
  align-items: center;
  gap: 48px;
}`, "",{"version":3,"sources":["webpack://./src/features/ShortInformation/ui/ShortInformation.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,qBAAA;EACA,6BAAA;EACA,SAAA;AACF;AACE;EANF;IAOI,sBAAA;EAEF;AACF;;AACA;EACE,WAAA;EACA,YAAA;EACA,mBAAA;EACA,cAAA;AAEF;;AACA;EACE,gBAAA;EACA,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,uBAAA;EACA,SAAA;AAEF;;AACA;EACE,yBAAA;EACA,eAAA;EACA,qBAAA;EACA,kBAAA;AAEF;;AACA;EACE,2BAAA;EACA,eAAA;EACA,qBAAA;EACA,iBAAA;EACA,kBAAA;AAEF;;AACA;EACE,aAAA;EACA,mBAAA;EACA,SAAA;AAEF","sourcesContent":[".shortInformationWrapper {\n  display: flex;\n  align-content: center;\n  justify-content: space-around;\n  gap: 48px;\n\n  @media screen and (max-width: 800px) {\n    flex-direction: column;\n  }\n}\n\n.infoImage {\n  width: 40vw;\n  height: 40vw;\n  border-radius: 100%;\n  margin: 0 auto;\n}\n\n.infoWrapper {\n  max-width: 595px;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  gap: 24px;\n}\n\n.infoTitle {\n  font-family: \"Inter-Bold\";\n  font-size: 20px;\n  letter-spacing: 0.4px;\n  text-align: center;\n}\n\n.infoDescription {\n  font-family: \"Inter-Medium\";\n  font-size: 16px;\n  letter-spacing: 1.8px;\n  line-height: 30px;\n  text-align: center;\n}\n\n.buttonWrapper {\n  display: flex;\n  align-items: center;\n  gap: 48px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"shortInformationWrapper": `ShortInformation_shortInformationWrapper__T3+Mn`,
	"infoImage": `ShortInformation_infoImage__zxxif`,
	"infoWrapper": `ShortInformation_infoWrapper__q8wDa`,
	"infoTitle": `ShortInformation_infoTitle__8-KJC`,
	"infoDescription": `ShortInformation_infoDescription__q0Ppu`,
	"buttonWrapper": `ShortInformation_buttonWrapper__DoUI4`
};
export default ___CSS_LOADER_EXPORT___;
